// const leaderSizeMap = [
//   {
//     browser: [0, 0],
//     ad_sizes: [[300, 600]],
//   },
//   {
//     browser: [980, 0],
//     ad_sizes: [[970, 250]],
//   }];

// const rectangleSizeMap = [
//   {
//     browser: [0, 0],
//     ad_sizes: [[300, 600]],
//   }];

// const dfpSizeMapping = {
//   GW_Desktop_Top_970x250: leaderSizeMap,
//   GW_Desktop_Sidebar1_300x600: rectangleSizeMap,
//   GW_Mobiel_Top_300x600: rectangleSizeMap,
//   GW_Mobiel_Low_300x600: rectangleSizeMap,
// };

// const defaultDfpSizeMap = rectangleSizeMap;

// function loadDfp($elem) {
//   if (typeof $elem.dfp !== 'function') {
//     console.error('dfp not available');
//     const $adContainer = $elem.closest('.ad-container');
//     if ($elem.length) $adContainer.remove();
//     return;
//   }

//   if (typeof dfpID === 'undefined') {
//     console.error('dfpID not defined');
//     return;
//   }

//   const sizeMapping = { ...dfpSizeMapping };
// //   // eslint-disable-next-line no-plusplus
//   for (let elIdx = 0; elIdx < $elem.length; elIdx++) {
//     const sizeMapIdentifier = $($elem[elIdx]).data('size-mapping');
//     if (typeof sizeMapIdentifier === 'undefined') {
//       // eslint-disable-next-line no-continue
//       continue;
//     }

//     // eslint-disable-next-line no-prototype-builtins
//     if (!sizeMapping.hasOwnProperty(sizeMapIdentifier)) {
//       sizeMapping[sizeMapIdentifier] = defaultDfpSizeMap;
//     }
//   }

//   return $elem.dfp({
//     dfpID,
//     enableSingleRequest: true,
//     disableInitialLoad: false,
//     companionAds: false,
//     collapseEmptyDivs: 'original',
//     setCentering: (window.innerWidth < 992),
//     disablePublisherConsole: false,
//     refreshExisting: false,
//     isLazyLoaded: false,
//     sizeMapping,
//   });
// }

// jQuery(document)
//   .ready(($) => {
//     const $adDivs = $('.adunit:not(".display-block")');
//     loadDfp($adDivs);
//   });

/*
  See https://developers.google.com/publisher-tag/guides/learn-basics for documentation on how to implement these ads.
*/

// Don't make this a let or const, it only works with var for some reason.
// eslint-disable-next-line no-var
var googletag = window.googletag || {};
googletag.cmd = googletag.cmd || [];

/*
  This variable defines the ad slot sizes.
  For more info on formatting see:
  https://developers.google.com/publisher-tag/guides/ad-sizes
*/
const AD_SLOT_SIZES = {
  'leaderSizeMap': [
    [
      [980, 0],
      [
        [728, 90],
        [970, 250],
      ],
    ],
    [
      [0, 0],
      [
        [300, 250],
        [250, 250],
      ],
    ],
  ],
  'rectangleSizeMap': [
    [
      [980, 0],
      [
        [300, 600],
        [300, 250],
      ],
    ],
    [[0, 0], [[300, 250]]],
  ],
};

const dfpSizeMapping = {
  GW_Desktop_Top_970x250: AD_SLOT_SIZES.leaderSizeMap,
  GW_Desktop_Sidebar1_300x600: AD_SLOT_SIZES.rectangleSizeMap,
  GW_Mobiel_Top_300x600: AD_SLOT_SIZES.rectangleSizeMap,
  GW_Mobiel_Low_300x600: AD_SLOT_SIZES.rectangleSizeMap
};

/*
  This function loads a single advertisement using the Google Publisher Tag API.
  The variables needed for the API are defined in TWIG templates and picked with data attributes.
*/
function loadAdvertisement(type, adSizes) {

  const name = type;
  let slot;

  // eslint-disable-next-line , prefer-arrow-callback
  googletag.cmd.push(function setupAdSlot() {
    const slots = googletag.pubads().getSlots
      ? googletag.pubads().getSlots()
      : [];

    slot = googletag
      .defineSlot(`/${window.dfpID}/${type}`, adSizes[0], name)
      .addService(googletag.pubads())
      .setCollapseEmptyDiv(false)
      .defineSizeMapping(adSizes);

    googletag.pubads().disableInitialLoad();
    googletag.enableServices();
    slots.push(slot);
  });

  // eslint-disable-next-line prefer-arrow-callback
  googletag.cmd.push(function displayAndRefreshAdSlot() {
    googletag.display(name);
    googletag.pubads().refresh([slot]);
  });
}

/*
  This function loops over all ad units on the page and loads them if they are not loaded yet.
  Uses loadAdvertisement.
  Is also used in on-infinite-scroll-post-load.js.
*/
function fillAllNonLoadedAdUnits() {
  $('.adunit:not(.display-block)').each((index, $ad) => {
    loadAdvertisement(
      $($ad).data('type'),
      dfpSizeMapping[$($ad).data('type')],
    );
    $($ad).addClass('display-block');
  });
}

fillAllNonLoadedAdUnits();
